import Image from "next/image";
import errorImage from "../public/assets/images/error.svg";

export default function Home() {
  return (
    <>
      <div className="container">
        <div className="row align-items-center mycontainer mymain">
          <div className="col-12 mx-auto">
            <div className="card denied-card col-12 col-sm-8 col-md-5 col-lg-4 m-auto py-4 px-3">
              <div className="card-body m-auto text-center px-0">
                <Image src={errorImage} alt="error" width={100} height={100} />
                <p className="my-2">
                  Please try again with a donation code. <br />
                  <span className="text-success"> Thank you.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
